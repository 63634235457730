/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Yo lo veo muy claro, hace tiempo que la exploración espacial dejó de ser un tema de importancia para los gobiernos. La llegada de los estadounidenses a la luna es verdad que marcó un hito, desde entonces la exploración espacial se paralizó y desde que terminó la guerra fría ya no se han vuelto a hacer grandes avances en cosmonáutica, quitando de unos cuantos robots que se han mandado a marte y la mitad se han perdido. Los fondos para la NASA que anteriormente parecían sin limites ahora se ven ahogados y más aun con la crisis. Pero por lo raro que pueda parecer el sector privado se está metiendo en este tema de cabeza, el turismo espacial es posiblemente uno de los mayores lujos que se pueda permitir alguien. Pero aún así hay un pequeño nicho de mercado en la explotación comercial del espacio que aunque necesitará una gran inversión puede llegar a ofrecer grandes beneficios. Uno de los principales problemas que tiene son las trabas que ponen los gobiernos a lanzar naves al espacio. Pero ya se están dando los primeros pasos, se ha conseguido poner en el espacio ", React.createElement(_components.a, {
    href: "http://www.neoteo.com/regresa-del-espacio-la-primera-capsula-privada.neo?utm_source=feedburner&utm_medium=feed&utm_campaign=Feed%3A+Neoteo+%28Neoteo+Anotaciones+Completas%29"
  }, "la primera capsula retornable creada por una empresa privada"), ". Espero que en el futuro haya más avances en este sentido, primero serán viajes de órbita a la tierra, después estaciones espaciales dedicadas al turismo, más tarde viajes a la luna y todo esto servirá para que los viajes espaciales se abaraten y la exploración del sistema solar por el hombre pueda llevarse a cabo."), "\n", React.createElement(_components.p, null, "Image Credit: http://lifeboat.com/"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
